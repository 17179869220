import Rails from "@rails/ujs";

// Overrides default rails confirmation with bootstrap 4 confirm dialog
Rails.confirm = function(message, element) {
    let $element = $(element)
    let $dialog = new SimpleAlert();
    const confirmText = $element.data('yes')
    const cancelText = $element.data('no')
    $dialog.confirm(message, confirmText, cancelText, function($yes){
        $yes.click(function(event) {
            event.preventDefault()
            let old = Rails.confirm
            Rails.confirm = function() { return true }
            // loadingListener($element.get(0))
            $element.get(0).click()
            Rails.confirm = old
        })
    })
  
  return false;
}